import _ from "lodash";
import { filterRowsIfEveryKeyValueIsAbsent } from "@/utils/collection";
import { validateTemplate } from "@/api/tasks";
import { validURL } from "@/utils/validate";

export const templateNameExists = (rule, value, template, isEdit = false) => {
  return new Promise(resolve => {
    validateTemplate(value)
      .then(res => {
        if (res.data.found && !isEdit) {
          resolve(true);
        } else if (
          res.data.found &&
          isEdit &&
          value !== template.template_name
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const urlFieldValidation = (rule, value) => {
  return validURL(value);
};

export const isEmptyVariableRules = (rule, value) => {
  let variableRules = filterRowsIfEveryKeyValueIsAbsent(
    value,
    "rule_value,variable_rule,description,import_configurable,default_value"
  );
  return _.isEmpty(variableRules);
};

export const variableRulesValidation = (
  rule,
  value,
  // eslint-disable-next-line no-unused-vars
  vueComponentInstance = null
) => {
  const variableRules = value;
  if (!_.isEmpty(variableRules)) {
    // if _.some return true, it means its invalid
    // if (
    //   _.some(variableRules, rule => {
    //     return rule.error && rule.error === "Cannot set secure variable value";
    //   })
    // ) {
    //   return "secure_variable_used";
    // }
    return !_.some(variableRules, rule => {
      // if all the relevant fields are empty, dont consider it to be a invalid variable rule
      if (
        !rule.rule_value &&
        !rule.variable_name &&
        !rule.description &&
        !rule.import_configurable
      ) {
        return false;
      } else {
        // if something is missing
        return (
          (rule.import_configurable && !rule.description) ||
          !rule.rule_value ||
          !rule.variable_name
        );
      }
    });
  } else {
    return true;
  }
};

export const eventHandlerNameValidation = (value, callback, vm, ehType) => {
  if (
    (_.isObject(value) && !_.isEmpty(value.canvas_name)) ||
    (_.isInteger(value) && value > 0)
  ) {
    if (
      value.canvas_id === -1 &&
      vm.$store.getters["canvas/canvasExists"](value.canvas_name)
    ) {
      vm.$set(value, "msg", "");
      callback(__("Canvas name already in use"));
    } else {
      callback();
    }
  } else {
    callback(__(`No ${ehType} event handler cannot be empty`));
  }
};

export const SeriesRulesValidation = (
  rule,
  value,
  // eslint-disable-next-line no-unused-vars
  vueComponentInstance = null
) => {
  const seriesRules = value;
  if (!_.isEmpty(seriesRules)) {
    return !_.some(seriesRules, rule => {
      if (
        !rule.series_color &&
        !rule.series_name &&
        !rule.series_query_condition &&
        !rule.series_stack_group_name
      ) {
        return false;
      } else {
        const isAnyFieldFilled =
          rule.series_color ||
          rule.series_stack_group_name ||
          rule.series_name ||
          rule.series_query_condition;

        const isNameAndConditionFilled =
          rule.series_name && rule.series_query_condition;

        return isAnyFieldFilled && !isNameAndConditionFilled;
      }
    });
  } else {
    return true;
  }
};
