<script>
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import List from "@/views/manage/node-logging-profiles/webhook-profiles/pages/list";
import CreateOrEdit from "@/views/manage/node-logging-profiles/webhook-profiles/pages/createOrEdit";
import { mapState } from "vuex";
import { NODE_LOG_PROFILE } from "@/constants/nodeLoggingProfiles";
import _ from "lodash";

export default {
  name: "WebhookProfilesManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },
  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("nodeLoggingProfiles", {
      contents: state => state.webhookProfiles
    })
  },

  methods: {
    initContent() {
      return {
        profile_type: NODE_LOG_PROFILE.WEBHOOK_PROFILE,
        profile_name: "",
        url: "",
        authentications: { auth_type: "none", username: "", password: "" },
        headers: [],
        http_method: "POST",
        is_enabled: 1
      };
    }
  },
  mounted() {
    this.contentType = _.startCase(NODE_LOG_PROFILE.WEBHOOK_PROFILE);
    this.primaryKey = "profile_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
