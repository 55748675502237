export default [
  {
    displayText: __("Voice"),
    label: "Voice",
    active: false,
    icon: "icon-voice.svg",
    permission: "voice task"
  },
  {
    displayText: __("Messaging"),
    label: "Messaging",
    active: false,
    icon: "icon-messaging.svg",
    permission: "Messaging"
  },
  {
    displayText: __("Workflow"),
    label: "Workflow",
    active: false,
    icon: "icon-workflow.svg",
    permission: "Workflow"
  },
  {
    displayText: __("ChatBot"),
    label: "ChatBot",
    active: false,
    icon: "icon-chatbot.svg",
    permission: "ChatBot"
  },
  {
    displayText: __("WhatsApp"),
    label: "WhatsApp",
    active: false,
    icon: "icon-chatbot.svg",
    permission: "Whatsapp"
  }
];
