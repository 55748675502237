<script>
import { mapState } from "vuex";
import { getTasksForProfile } from "@/api/nodeLoggingProfiles";
import _ from "lodash";

export default {
  data() {
    return {
      showProfileUsedInTasksModal: false,
      fetchingTasks: false,
      tasks: {}
    };
  },
  computed: {
    ...mapState("nodeLoggingProfiles", {
      profileFilter: state => state.profileFilter
    })
  },
  methods: {
    getTaskListForProfile(profile) {
      this.$emit("extend-item");
      this.showProfileUsedInTasksModal = true;
      if (_.isEmpty(this.tasks)) {
        this.fetchingTasks = true;
        getTasksForProfile(profile)
          .then(({ data }) => {
            this.tasks = data.tasks;
          })
          .catch(() => {})
          .finally(() => (this.fetchingTasks = false));
      }
    }
  },
  watch: {
    "contentFormInfo.profile_id": {
      immediate: true,
      handler() {
        this.tasks = {};
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
