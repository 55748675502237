<template>
  <div class="queryParamsTable">
    <el-table fit :data="queryParams" style="width: 100%">
      <el-table-column
        v-if="useAsMultiPartParams"
        prop="type"
        :label="__('TYPE')"
      >
        <template slot-scope="scope">
          <el-form-item class="no-red-highlight">
            <el-select
              :value="scope.row.type"
              @input="updateType($event)(scope.row)"
              :placeholder="__('Select')"
              default-first-option
            >
              <el-option
                v-for="item in multiPartOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>

      <el-table-column prop="key" :label="__(keyLabel)" v-if="displayKey">
        <template slot-scope="scope">
          <!--          <at-in :members="variableNames" at="{{" suffix="}}">-->
          <!--            <el-input v-model="scope.row.key"></el-input>-->
          <!--          </at-in>-->
          <el-form-item
            :error="hasInvalidVariable(scope.row.key, useVariablesInKey)"
            :class="classes(scope.row, 'key')"
          >
            <input-variable-popper
              v-if="useVariablesInKey"
              :is-text-area="false"
              v-model="scope.row.key"
              :include-secure-variables="useSecureVariables"
              force-reinitialize
            />
            <el-input v-else v-model="scope.row.key"></el-input>
          </el-form-item>
        </template>
      </el-table-column>

      <el-table-column prop="value" :label="__(valueLabel)">
        <template slot-scope="scope">
          <!--          <el-input v-model="scope.row.value"></el-input>-->
          <el-form-item
            :error="hasInvalidVariable(scope.row.value, useVariablesInValue)"
            :class="classes(scope.row, 'value')"
          >
            <file-uploader-minimal
              style="width: 100%"
              v-if="useAsMultiPartParams && scope.row.type === 'file'"
              :folder="generateFolderName"
              :file-url="scope.row.value"
              :file-name="fileName(scope.row)"
              encrypt-contents
              :convert-audio="false"
              :max-size-in-mb="50"
              @on-success="handleFileUploadSuccess($event)(scope.row)"
              @on-delete="handleFileRemove($event)(scope.row)"
            />

            <input-variable-popper
              v-else-if="useAsMultiPartParams && scope.row.type === 'audio'"
              :is-text-area="false"
              v-model="scope.row.value"
              onlyAudioVariables
              force-reinitialize
            />

            <input-variable-popper
              v-else-if="useVariablesInValue"
              :is-text-area="false"
              :include-secure-variables="useSecureVariables"
              v-model="scope.row.value"
              force-reinitialize
            />
            <el-input v-else v-model="scope.row.value"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        v-if="useDefaultKeyField"
        :prop="defaultKeyProperty"
        :label="__(defaultKeyLabel)"
      >
        <template slot-scope="scope">
          <el-form-item class="no-red-highlight">
            <el-input v-model="scope.row[defaultKeyProperty]"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        v-if="useDefaultField"
        :prop="defaultValueProperty"
        :label="__(defaultValueLabel)"
      >
        <template slot-scope="scope">
          <el-form-item class="no-red-highlight">
            <span v-if="useAsMultiPartParams && scope.row.type === 'file'">
              {{ getTailName(scope.row[defaultValueProperty]) }}</span
            >
            <span
              v-else-if="useAsMultiPartParams && scope.row.type === 'audio'"
            >
              {{ getAudioDefault(scope.row) }}</span
            >
            <el-input
              v-else
              v-model="scope.row[defaultValueProperty]"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column class-name="cell-item-pointer" width="50px">
        <template slot-scope="scope">
          <span @click="removeQueryParam(scope.$index)">
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
import InputVariablePopper from "./InputVariablePopper";
import FileUploaderMinimal from "@/components/uploaders/types/FileUploaderMinimal";
import path from "path";
import VariableChecks from "@/views/mixins/VariableChecks";

export default {
  mixins: [VariableChecks],
  props: {
    value: {
      required: true,
      type: [String, Array]
    },
    useDefaultField: {
      required: false,
      type: Boolean,
      default: true
    },
    useDefaultKeyField: {
      required: false,
      type: Boolean,
      default: true
    },
    keyLabel: {
      required: false,
      type: String,
      get default() {
        return __("KEY");
      }
    },
    valueLabel: {
      required: false,
      type: String,
      get default() {
        return __("VALUE");
      }
    },
    defaultValueProperty: {
      required: false,
      type: String,
      default: "default"
    },
    defaultKeyProperty: {
      required: false,
      type: String,
      default: "default_key"
    },
    defaultValueLabel: {
      required: false,
      type: String,
      get default() {
        return __("TEST VALUE");
      }
    },
    defaultKeyLabel: {
      required: false,
      type: String,
      get default() {
        return __("TEST KEY");
      }
    },
    displayKey: {
      type: Boolean,
      default: true,
      required: false
    },
    useVariablesInKey: {
      type: Boolean,
      default: true,
      required: false
    },
    useVariablesInValue: {
      type: Boolean,
      default: true,
      required: false
    },
    useSecureVariables: {
      type: Boolean,
      default: true,
      required: false
    },
    useAsMultiPartParams: {
      type: Boolean,
      required: false,
      default: false
    },
    task_id: {
      type: [Number, String],
      required: false
    }
  },
  components: {
    FileUploaderMinimal,
    InputVariablePopper
  },
  data() {
    const query_param = { key: "", value: "" };
    return {
      convertedToArray: false,
      query_param: _.cloneDeep(query_param),
      query_params: [],
      multiPartOptions: [
        {
          value: "text",
          label: __("TEXT")
        },
        {
          value: "file",
          label: __("FILE")
        },
        {
          value: "remote-file",
          label: __("REMOTE FILE")
        },
        {
          value: "audio",
          label: __("AUDIO")
        }
      ]
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit,
      showExpressionBuilder: state => state.showExpressionBuilder
    }),

    displayExpressionBuilderModal: {
      get: function() {
        return this.showExpressionBuilder;
      },

      set: function(val) {
        this.setExpressionBuilderModalShow(val);
      }
    },

    generateFolderName() {
      if (this.task_id) {
        return `tasks/${this.task_id}/multipart_files`;
      }
      return `multipart_files`;
    },

    classes() {
      return (row, key) => {
        if (!row[key]) {
          return "no-red-highlight";
        } else {
          let hasError = this.hasInvalidVariable(
            row[key],
            key === "key" ? this.useVariablesInKey : this.useVariablesInValue
          );
          if (!hasError) {
            return "no-red-highlight";
          }
        }
      };
    },

    hasInvalidVariable() {
      return (value, checkForInvalidVariable = true) => {
        let invalidVariables = this.invalidVariableIdsPresentInAValue(
          value,
          checkForInvalidVariable
        );
        return _.isEmpty(invalidVariables)
          ? ""
          : __("invalid variable, :invalidVariables", {
              invalidVariables: invalidVariables[0]
            });
      };
    },

    fileName() {
      return row => this.getTailName(row.value);
    },

    queryParams() {
      return this.query_params;
    },

    query_params_json() {
      let query_params = [...this.query_params];
      if (!query_params.length) {
        if (this.convertedToArray) {
          return "[]";
        }
        return query_params;
      }

      let params = _.map(query_params, query_param => {
        if (!this.displayKey) {
          query_param.key = query_param.value;
        }
        let obj = {
          ...query_param,
          key: query_param.key.toString().trim(),
          value: query_param.value.toString().trim()
        };

        if (this.useDefaultField) {
          obj[this.defaultValueProperty] = query_param[
            this.defaultValueProperty
          ].trim();
        }

        if (this.useDefaultKeyField) {
          obj[this.defaultKeyProperty] = query_param[
            this.defaultKeyProperty
          ].trim();
        }

        return obj;
      });

      if (this.convertedToArray) {
        return JSON.stringify(params);
      }
      return params;
    },
    ...mapGetters("variables", {
      // variableNames: "variableNames",
      getVariablesDefaultValue: "getVariablesDefaultValue"
    })
  },
  methods: {
    ...mapActions("canvas", {
      setExpressionBuilderModalShow: "setExpressionBuilderModalShow"
    }),

    addNewQueryParam() {
      this.query_params.push({ ...this.query_param });
    },
    removeQueryParam(index) {
      this.query_params.splice(index, 1);
    },

    getName(p) {
      return path.basename(p);
    },

    getTailName(p) {
      return _(_.split(this.getName(p), "_"))
        .tail()
        .value()
        .join("_");
    },

    setAudioTestValue(row) {
      let value = row.value;
      let regexForVariable = new RegExp(/{{(.+?)(?:\|variable)?(?:}})/g);
      if (regexForVariable.test(value)) {
        let defaultValue = this.getVariablesDefaultValue(
          parseInt(value.substring(2, value.length - 2))
        );
        if (
          _.isEmpty(row.test_value) ||
          (!_.isEmpty(row.test_value) && defaultValue !== row.test_value)
        ) {
          this.$set(row, this.defaultValueProperty, defaultValue);
        }
      }
    },

    getAudioDefault(row) {
      if (!_.isEmpty(row.value)) {
        this.setAudioTestValue(row);
        return this.getTailName(row[this.defaultValueProperty]);
      }
    },

    handleFileUploadSuccess({ path }) {
      return row => {
        row.value = path;
        row[this.defaultValueProperty] = path;
        this.$emit("file-uploaded", path);
      };
    },

    handleFileRemove() {
      return row => (row.value = "");
    },

    updateType(value) {
      return row => {
        row.type = value;
        row.value = "";

        if (this.useDefaultField) {
          row[this.defaultValueProperty] = "";
        }
      };
    },

    initializeQueryParams(queryParams) {
      if (!this.isNodeSubmit) {
        if (!Array.isArray(queryParams)) {
          this.convertedToArray = true;
          queryParams = Object.assign({}, JSON.parse(queryParams));
        } else {
          queryParams = Object.assign({}, queryParams);
        }
        if (!_.isEmpty(queryParams)) {
          this.query_params = _.map(queryParams, queryParam => {
            if (
              this.useDefaultField &&
              !Object.keys(queryParam).includes(this.defaultValueProperty)
            ) {
              this.$set(queryParam, this.defaultValueProperty, "");
            }

            if (
              this.useDefaultKeyField &&
              !Object.keys(queryParam).includes(this.defaultKeyProperty)
            ) {
              this.$set(queryParam, this.defaultKeyProperty, "");
            }

            if (
              this.useAsMultiPartParams &&
              !Object.keys(queryParam).includes("type")
            ) {
              this.$set(queryParam, "type", "text");
            }

            return queryParam;
          });
        }
        if (
          !_.some(this.query_params, queryParam => {
            return !queryParam.key.length;
          })
        ) {
          this.addNewQueryParam();
          this.$emit("input", this.query_params_json);
        }
      }
    }
  },
  watch: {
    useDefaultField: {
      immediate: true,
      handler: function(useDefault) {
        if (
          useDefault &&
          !Object.keys(this.query_param).includes(this.defaultValueProperty)
        ) {
          this.$set(this.query_param, this.defaultValueProperty, "");
        }
      }
    },

    useDefaultKeyField: {
      immediate: true,
      handler: function(useDefault) {
        if (
          useDefault &&
          !Object.keys(this.query_param).includes(this.defaultKeyProperty)
        ) {
          this.$set(this.query_param, this.defaultKeyProperty, "");
        }
      }
    },
    value: {
      immediate: true,
      handler: "initializeQueryParams"
    },
    queryParams: {
      immediate: true,
      deep: true,
      handler(val) {
        let invalidVariablesFoundInKey =
          this.useVariablesInKey &&
          _.some(val, queryParam =>
            this.hasInvalidVariable(queryParam.key, this.useVariablesInKey)
          );
        let invalidVariablesFoundInValue =
          this.useVariablesInValue &&
          _.some(val, queryParam =>
            this.hasInvalidVariable(queryParam.value, this.useVariablesInValue)
          );
        this.$emit(
          "invalid-variables",
          invalidVariablesFoundInKey || invalidVariablesFoundInValue
        );
      }
    },

    query_params_json(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.queryParamsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.queryParamsTable ::v-deep .el-table--medium td {
  padding: 15px 0 0 0;
}

.queryParamsTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
  }
}

.queryParamsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.queryParamsTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}
</style>
