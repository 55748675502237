<template>
  <div>
    <page-header
      style="padding: 20px 30px;width: calc(100% - 45px)"
      :title="__('Used In Tasks')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    ></page-header>
    <el-scrollbar :native="false">
      <div class="tasks-container">
        <template v-if="!loading && tasksInGroup.length">
          <div
            class="task-container"
            :key="index"
            v-for="(task, index) in tasksInGroup"
          >
            <content-card
              :icon="getIcon"
              :card-text="task.task_name"
              delete-tooltip="Remove"
              @delete="unmap(task)"
            />
          </div>
        </template>
        <div v-if="!loading && !tasksInGroup.length">
          <!-- eslint-disable-next-line -->
          {{ __("This profile is not currently being used by any :tasktype tasks.", {
                tasktype: this.taskFilter
              }
            )
          }}
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader";
import ContentCard from "@/components/ContentCard";
import { unmapTaskFromProfile } from "@/api/nodeLoggingProfiles";
import TaskTypes from "@/constants/nav-items/task-types";

export default {
  components: { ContentCard, PageHeader },
  props: {
    tasks: {
      required: true,
      type: [Object, Array]
    },
    profile: {
      required: true,
      type: Object
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navItems: TaskTypes,
      taskFilter: null
    };
  },
  computed: {
    tasksInGroup() {
      return _.get(this.tasks, this.taskFilter) || [];
    },

    getIcon() {
      let navItem = this.activeNavItem;
      return (navItem && navItem.icon) || "icon-voice.svg";
    },

    activeNavItem() {
      return _.find(this.navItems, navItem => navItem.active);
    }
  },
  methods: {
    assignTaskFilter() {
      if (!this.taskFilter || !this.can(this.taskFilter)) {
        if (this.can("Voice")) {
          this.taskFilter = "voice";
        } else if (this.can("Messaging")) {
          this.taskFilter = "messaging";
        } else if (this.can("Workflow")) {
          this.taskFilter = "workflow";
        } else if (this.can("Chatbot")) {
          this.taskFilter = "chatbot";
        }
      }
      this.handleNavItemChanged({ label: _.upperFirst(this.taskFilter) });
    },
    handleNavItemChanged(navItem) {
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
      this.taskFilter = navItem.label.toLowerCase();
    },
    unmap(task) {
      this.$confirm(
        __("This will stop sending logs for this task to this profile"),
        __("Stop logs"),
        {
          confirmButtonText: __("Stop logs"),
          cancelButtonText: __("Cancel"),
          type: "warning"
        }
      )
        .then(() => {
          unmapTaskFromProfile({
            task_id: task.task_id,
            profile_id: this.profile.profile_id,
            profile_type: this.profile.profile_type
          })
            .then(() => {
              _.set(
                this.tasks,
                this.taskFilter,
                _.filter(
                  this.tasksInGroup,
                  taskInGroup => taskInGroup.task_id !== task.task_id
                )
              );
              this.$message({
                type: "success",
                message: __("Successfully unmapped")
              });
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {});
    }
  },
  created() {
    this.assignTaskFilter();
  }
};
</script>

<style scoped lang="scss">
.page-header {
  padding-top: 0 !important;
}

.tasks-container {
  max-height: 50vh;
  min-height: 150px;
  padding: 20px 30px;
  margin-left: -10px;
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .task-container {
    margin-top: 10px;
  }

  > div {
    margin-left: 10px;
  }

  ::v-deep .el-card {
    &:hover {
      .deleteBtn {
        opacity: 1;
      }
    }
  }
}
</style>
