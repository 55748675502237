<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Profile Name") }}</div>
    <div class="details">{{ contentFormInfo.profile_name }}</div>

    <div class="titles">{{ __("Webhook URL") }}</div>
    <div class="details">{{ contentFormInfo.url }}</div>

    <div class="titles">{{ __("HTTP method") }}</div>
    <div class="details">{{ contentFormInfo.http_method }}</div>

    <div class="titles profile-tasks">
      <div class="click-to-open">
        <span class="trigger" @click="getTaskListForProfile(contentFormInfo)">{{
          __("Show tasks using this profile")
        }}</span>
      </div>
    </div>

    <el-button
      @click="handleEditBtnClick"
      class="editBtn"
      :disabled="!canWrite()"
      >{{ __("Edit Profile") }}</el-button
    >
    <el-button
      @click="handleDelete(contentFormInfo)"
      style="margin-top: 10px"
      class="deleteBtn"
      :disabled="!canWrite()"
      >{{ __("Delete") }}
    </el-button>

    <el-dialog
      v-if="openModal"
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isLoading"
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header :title="__('profile')" :contentId="id" /> </el-col
            ></el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="profile_name" :label="__('Profile Name')">
                  <el-input
                    :placeholder="__('Enter Profile Name')"
                    v-model="contentForm.profile_name"
                /></el-form-item> </el-col
            ></el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="url" :label="__('Webhook URL')">
                  <div class="form-item-url">
                    <el-select
                      v-model="contentForm.http_method"
                      :placeholder="__('Select')"
                      default-first-option
                      class="http_method_dropdown"
                    >
                      <el-option
                        v-for="item in httpMethods"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <el-input
                      class="url-input-field"
                      :placeholder="__('Enter Webhook URL')"
                      v-model="contentForm.url"
                    />
                  </div>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-tabs v-model="activeTab" class="tabs">
                  <el-tab-pane :label="__('Headers')" name="headers">
                    <el-scrollbar :native="false">
                      <div style="max-height: 40vh">
                        <query-params
                          v-model="headers"
                          :use-variables-in-key="false"
                          :use-variables-in-value="false"
                          :use-default-field="false"
                          :use-default-key-field="false"
                        ></query-params>
                      </div>
                    </el-scrollbar>
                  </el-tab-pane>
                  <el-tab-pane
                    :label="__('Authentications')"
                    name="authentications"
                  >
                    <el-row type="flex">
                      <el-col :span="6">
                        <el-form-item :label="__('Auth Type')">
                          <el-select
                            v-model="contentForm.authentications.auth_type"
                            default-first-option
                          >
                            <el-option
                              v-for="type in auth_types"
                              :key="type.value"
                              :label="type.label"
                              :value="type.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col
                        :span="18"
                        style="margin-top: 20px"
                        v-if="
                          contentForm.authentications.auth_type === 'basic' ||
                            contentForm.authentications.auth_type === 'digest'
                        "
                      >
                        <el-row type="flex">
                          <el-col :span="2"></el-col>
                          <el-col :span="6" style="padding-top: 5px">
                            {{ __("Username") }}
                          </el-col>
                          <el-col :span="16">
                            <el-form-item>
                              <el-input
                                :placeholder="__('Username')"
                                autocomplete="off"
                                v-model="contentForm.authentications.username"
                              ></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row type="flex">
                          <el-col :span="2"></el-col>
                          <el-col :span="6" style="padding-top: 5px">
                            {{ __("Password") }}
                          </el-col>
                          <el-col :span="16">
                            <el-form-item>
                              <el-input
                                :placeholder="__('Password')"
                                show-password
                                autocomplete="new-password"
                                v-model="contentForm.authentications.password"
                              ></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col
                        v-if="contentForm.authentications.auth_type === 'none'"
                        :span="18"
                      >
                        <el-row type="flex">
                          <el-col :span="6"></el-col>
                          <el-col :span="24" style="padding-top: 45px"
                            >{{
                              __("This request does not use any authorization")
                            }}
                          </el-col>
                          <el-col :span="6"></el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
          </el-form>

          <div
            slot="footer"
            style="display: flex; margin-top: 35px; margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div></el-scrollbar
      ></el-dialog
    >
    <tasks-for-profile-dialog
      v-if="showProfileUsedInTasksModal"
      :profile="contentFormInfo"
      :show-modal="showProfileUsedInTasksModal"
      :tasks="tasks"
      :loading="fetchingTasks"
      @close="showProfileUsedInTasksModal = false"
    />
  </div>
</template>

<script>
import BaseContent from "@/views/build/content/mixins/BaseContent";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import _ from "lodash";
import { filterRowsIfEveryKeyValueIsAbsent } from "@/utils/collection";
import { checkProfileNameExists } from "@/api/nodeLoggingProfiles";
import { urlFieldValidation } from "@/utils/formValidationRules";
import { NODE_LOG_PROFILE } from "@/constants/nodeLoggingProfiles";
import TasksForProfileDialog from "@/views/manage/node-logging-profiles/components/tasksForProfileDialog";
import GetTasksForProfiles from "@/views/manage/node-logging-profiles/mixins/GetTasksForProfiles";
import QueryParams from "@/views/build/callflow/components/node-type-forms/components/QueryParams";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";

export default {
  components: { PageHeader, QueryParams, TasksForProfileDialog, SaveButton },
  mixins: [GetTasksForProfiles, BaseContent, BaseContentInfoPanel],
  data() {
    const validateURL = (rule, value, callback) => {
      let isValid = urlFieldValidation(rule, value);
      isValid ? callback() : callback(__("enter valid URL"));
    };
    const validateProfileName = async (rule, value, callback) => {
      let hasError = false;
      await checkProfileNameExists(value, NODE_LOG_PROFILE.WEBHOOK_PROFILE)
        .then(res => {
          if (res.data.found && value !== this.contentFormInfo.profile_name) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });

      if (hasError) {
        callback(__("Profile Name already taken"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        profile_name: [
          {
            required: true,
            message: __("Profile Name cannot be empty"),
            trigger: "blur"
          },
          { validator: validateProfileName, trigger: "blur" }
        ],
        url: [
          {
            required: true,
            message: __("Webhook URL cannot be empty"),
            trigger: "blur"
          },
          { validator: validateURL, trigger: "blur" }
        ]
      },
      isSubmitting: false,
      headersBackup: [],
      httpMethods: [
        {
          value: "POST",
          label: "POST"
        },
        {
          value: "PUT",
          label: "PUT"
        }
      ],
      activeTab: "headers",
      auth_types: [
        {
          value: "none",
          label: __("None")
        },
        {
          value: "basic",
          label: __("Basic")
        },
        {
          value: "digest",
          label: __("Digest")
        }
      ]
    };
  },
  computed: {
    ...mapState("nodeLoggingProfiles", {
      isLoading: state => state.loading
    }),
    headers: {
      get() {
        return this.isSubmitting
          ? this.headersBackup
          : this.contentForm.headers;
      },
      set(val) {
        this.contentForm.headers = val;
        this.headersBackup = _.cloneDeep(val);
      }
    }
  },
  methods: {
    ...mapActions("nodeLoggingProfiles", {
      deleteContentMethod: "deleteNodeLoggingProfile",
      undoDeleteContent: "undoDeleteProfile",
      createProfile: "createNodeLoggingProfile",
      updateProfile: "updateNodeLoggingProfile"
    }),

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleCancelBtn() {
      this.resetForm("contentForm");
      this.activeTab = "headers";
      this.handleCancel();
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createProfile : this.updateProfile;
          this.isSubmitting = true;
          this.contentForm.headers = filterRowsIfEveryKeyValueIsAbsent(
            this.contentForm.headers,
            "key,value"
          );
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("profile created successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("profile updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancelBtn();
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            })
            .finally(() => {
              this.isSubmitting = false;
            });
        }
      });
    },

    backButtonHandler() {
      if (this.showProfileUsedInTasksModal) {
        this.showProfileUsedInTasksModal = false;
        return false;
      } else if (this.openModal) {
        this.handleCancel();
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

.page-header {
  padding-left: 0;
}

.form-item-url {
  display: flex;

  .http_method_dropdown {
    width: 100px;
    color: black;

    ::v-deep .el-input__inner {
      border-color: #a0a8b5 !important;

      &:focus {
        border-color: #a0a8b5 !important;
      }
    }

    ::v-deep .el-input.is-focus .el-input__inner {
      border-color: #a0a8b5 !important;

      &:focus {
        border-color: #a0a8b5 !important;
      }
    }

    ::v-deep .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  .url-input-field {
    flex: 1;

    ::v-deep .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.http_methods {
  border-radius: 4px 0 0 4px !important;
}

.profile-tasks {
  .click-to-open {
    display: flex;
    color: var(--theme-color);
    margin: 20px 0;
    font-size: 0.85rem;

    .trigger {
      cursor: pointer;
    }
  }
}
</style>
