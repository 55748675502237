<template>
  <div class="minimal-file-uploader">
    <el-popover
      v-if="showFileName"
      v-show="fileUrl"
      :open-delay="500"
      placement="top-start"
      width="200"
      trigger="hover"
    >
      {{ fileName }}
      <div
        slot="reference"
        style="max-width: 100px; text-overflow: ellipsis ;white-space: nowrap;
  overflow: hidden; margin-left: 20px;"
      >
        {{ fileName }}
      </div>
    </el-popover>

    <el-upload
      v-if="!fileUrl"
      :headers="headers"
      :action="getUploadUrl"
      :data="formData"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-progress="handleUploadProgress"
      :show-file-list="false"
      :before-upload="beforeFileUpload"
      :on-change="handleChange"
      :multiple="false"
    >
      <el-popover
        :open-delay="500"
        placement="top-start"
        width="350"
        trigger="hover"
      >
        <span class="el-upload__tip"
          >{{ __("files") }}
          <span v-if="allowTypes.length > 0">
            {{
              __(" with extension :allow and", { allow: allowedTypesReadable })
            }} </span
          >{{ __(" with size less than :max mb", { max: maxSizeInMb }) }}</span
        >
        <div slot="reference">
          <el-button
            v-loading="isLoading"
            class="upload-btn"
            slot="trigger"
            size="small"
            type="primary"
            style="padding: 6px 16px;"
          >
            <img
              src="@/assets/icons/icon-upload-new.svg"
              onload="SVGInject(this)"
              alt="icon-content"
              style="font-size: 12px; vertical-align: middle; margin-right: 2px;"
            />
            {{ __("Upload") }}
          </el-button>
        </div>
      </el-popover>
    </el-upload>
    <div
      v-else
      style="display: flex; justify-content: center; align-items: center"
    >
      <audio-player
        v-if="isAudio && fileUrl"
        :file="fileUrl"
        :simple-player="true"
        class="simple-player"
        style="display: inline-block;width: 32px"
        showClose="true"
      ></audio-player>
      <el-button
        class="remove-file"
        @click="handleDelete"
        size="mini"
        type="danger"
        icon="el-icon-delete"
        circle
      ></el-button>
    </div>
  </div>
</template>

<script>
import BaseUploader from "./../BaseUploader";
import AudioPlayer from "@/components/AudioPlayer";
import _ from "lodash";

export default {
  mixins: [BaseUploader],
  components: { AudioPlayer },
  props: {
    fileName: {
      required: false,
      type: String
    }
  },
  methods: {
    handleChange(file, fileList) {
      this.$emit("on-change", file, fileList);
    },
    handleDelete() {
      this.$emit("on-delete");
    }
  },
  computed: {
    isAudio() {
      return _.some(this.allowTypes, allowedType =>
        ["wav", "mp3"].includes(allowedType)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.minimal-file-uploader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .remove-file {
    margin-left: 5px;
  }
}

.simple-player {
  border-radius: 16px;
  display: inline-block;
  background: white;

  ::v-deep img {
    filter: invert(58%) sepia(52%) saturate(1516%) hue-rotate(166deg)
      brightness(96%) contrast(96%);
  }
}
</style>
