<template
  ><div style="width: 100%;" @click="handleClear">
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      :disabled="!canWrite()"
      >{{ __("Create Webhook Profile") }}</el-button
    >
    <div style="padding: 0 36px;margin-top: 165px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>

      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="webhookProfilesTable"
            v-loading="loading"
            :data="profiles"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            @current-change="handleCurrentChange"
            highlight-current-row
            class="list-table"
            v-show="canRead()"
          >
            <el-table-column :label="__('Profile Name')">
              <template slot-scope="scope">
                <span>{{ scope.row.profile_name }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('Webhook URL')">
              <template slot-scope="scope">
                <span>{{ scope.row.url }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('HTTP Method')">
              <template slot-scope="scope">
                <span>{{ scope.row.http_method }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "WebhookProfilesManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar
  },
  data() {
    return {
      search: "",
      // filters: ["xsi_profile_id"],
      sortByColumns: ["profile_name", "profile_id"],
      currentRow: ""
    };
  },
  computed: {
    ...mapState("nodeLoggingProfiles", {
      profiles: state => state.webhookProfiles,
      loading: state => state.loading
    })
  },
  methods: {
    ...mapActions("nodeLoggingProfiles", {
      contentAPI: "getNodeLoggingProfiles",
      deleteContentMethod: "deleteNodeLoggingProfile",
      undoDeleteContent: "undoDeleteProfile"
    }),
    handleClear() {
      this.$refs["webhookProfilesTable"].setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== undefined && val !== null && val !== -1) {
          this.$refs["webhookProfilesTable"] &&
            this.$refs["webhookProfilesTable"].setCurrentRow(
              this.profiles[val]
            );
        } else if (val === -1) {
          this.$refs["webhookProfilesTable"] &&
            this.$refs["webhookProfilesTable"].setCurrentRow();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
