<template>
  <el-dialog
    :visible.sync="modalFlag"
    show-close
    destroy-on-close
    width="70%"
    custom-class="tasks-used-by-profile"
  >
    <div v-loading="loading">
      <tasks-for-profile :tasks="tasks" :profile="profile" :loading="loading" />
    </div>
  </el-dialog>
</template>

<script>
import TasksForProfile from "@/views/manage/node-logging-profiles/components/tasksForProfile";

export default {
  components: { TasksForProfile },
  props: {
    profile: {
      required: true,
      type: Object
    },
    tasks: {
      required: true,
      type: [Object, Array]
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    },
    showModal: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      modalFlag: false
    };
  },
  created() {
    this.modalFlag = this.showModal;
  },
  watch: {
    modalFlag(val) {
      if (!val) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss">
.tasks-used-by-profile {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
