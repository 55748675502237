<template>
  <drag class="drag" :data="draggableData" :disabled="dragDisabled">
    <el-card
      :class="cardClass"
      shadow="hover"
      @click.native.stop="$emit('select')"
      @dblclick.native.stop="$emit('edit')"
      style="position:relative;"
    >
      <div class="inline-card no-select">
        <el-checkbox
          v-if="allowMultiSelect"
          style="margin-right: 10px"
          v-model="multiSelected"
          @change="$emit('select-multiple')"
        ></el-checkbox>
        <img :src="getCardIcon" alt="icon-content" />
        <div class="card-text">
          {{ cardText }}
        </div>

        <div
          v-if="showDelete && allowDrag"
          class="dragBtn"
          @mouseover="dragDisabled = false"
          @mouseleave="dragDisabled = true"
        >
          <i class="el-icon-rank"></i>
        </div>

        <!--        <el-button-->
        <!--          type="text"-->
        <!--          -->
        <!--          class="deleteBtn"-->
        <!--          -->
        <!--        >-->
        <el-tooltip v-if="showDelete" :content="deleteTooltip"
          ><i
            @click="$emit('delete')"
            class="el-icon-close cancel-icon cell-item-pointer"
          ></i
        ></el-tooltip>
        <!--        </el-button>-->
      </div>
    </el-card>
  </drag>
</template>

<script>
import { EventBus } from "@/EventBus";
import { Drag } from "vue-easy-dnd";
export default {
  name: "ContentCard",
  components: {
    Drag
  },
  data() {
    return {
      multiSelected: false,
      dragDisabled: true
    };
  },
  props: {
    cardClass: {
      type: Object,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: "icon-microphone.svg"
    },
    cardText: {
      type: String,
      required: true
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    deleteTooltip: {
      type: String,
      required: false,
      default: __("Delete")
    },
    allowMultiSelect: {
      default: false,
      type: Boolean
    },
    draggableData: {
      type: Object,
      required: false,
      default: () => {}
    },
    allowDrag: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    getCardIcon() {
      return require("@/assets/icons/" + this.icon);
    }
  },
  created() {
    EventBus.$on("de-select-all", () => {
      this.multiSelected = false;
    });
  },
  beforeDestroy() {
    EventBus.$off("de-select-all");
  }
};
</script>

<style scoped lang="scss">
.inline-card {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
  .card-text {
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 45px 2px 5px;
    flex-grow: 8;
  }
  .cancel-icon {
    position: absolute;
    right: 10px;
    opacity: 0;
  }
  .dragBtn {
    cursor: pointer;
    position: absolute;
    right: 30px;
    opacity: 0;
  }
  &.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  }
}

.drag {
  transition: all 0.5s;
}
</style>
